.SearchHeader-module__wrapper {
  padding: 16px;
  display: flex;
  gap: 16px;
  background-color: #fff;
  /* align-items: center; */
  flex-direction: column;
}

/* .wrapper img {
  width: 53px;
  height: 24px;
} */

@media screen and (min-width: 1025px) {

}

@media screen and (max-width: 1024px) {
  .SearchHeader-module__wrapper{
    min-height: 124px;
  }
}